<template>
  <div class>
    <headers />
    <div class="mb" style="padding:15px;padding-top: 60px !important;"></div>

    <div class="mb" style="padding:15px;" v-if="type=='address'">
      <div class="col-lg-12">
        <div class="search-result-box card-box">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="pb-4">
                <div class="input-group m-t-10" style="line-height:35px;">
                  <div class="lang" v-html="lan.address"></div>:&nbsp;&nbsp;
                  <input
                    class="form-control mr-1"
                    id="address"
                    style="width:400px;display:inline-block;flex: none !important;border-radius: 5px;"
                    type="search"
                    placeholder="address"
                    aria-label="address"
                    v-model="address"
                  />
                  <div class="lang" v-html="lan.distance" style="margin-left: 20px;"></div>
                  <div>:&nbsp;&nbsp;</div>
                  <input
                 
                    class="form-control mr-1"
                    style="width:100px !important;display:inline-block; flex: none !important;border-radius: 5px;"
                    type="number"
                    placeholder="Distance"
                    aria-label="Distance"
                    v-model="distance"
                  />
                  <div>m</div>
                  <div class="help">
                    <ctooltip
                      :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'"
                      :align="'right'"
                      :help_text="lan.help_cbreai_distance"
                    />
                  </div>

                  <span class="input-group-append" style="margin-left:10px;">
                    <button
                      type="button"
                      class="btn waves-effect waves-light btn-black"
                      @click="search"
                    >
                      <i class="fa fa-search mr-1"></i>
                      <div class="lang" v-html="lan.search"></div>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="types">
            <div class="custom-input">
              <input type="radio" value="company" v-model="type" />
              <div class="show-box radio"></div>
            </div>
            <div class="lang" v-html="lan.search_by_firm_name"></div>&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="custom-input">
              <input type="radio" value="address" v-model="type" />
              <div class="show-box radio"></div>
            </div>
            <div class="lang" v-html="lan.search_by_address"></div>
          </div>
          <ul class="tab-head nav nav-tabs" style="margin-top:20px">
            <li
              class="nav-item"
              data-id="tc1"
              @click="tab('tc1')"
              v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.map"></div>
              </a>
            </li>
            <li
              v-if="code!=''"
              class="nav-item"
              data-id="tc2"
              @click="tab('tc2')"
              v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.office_list"></div>
              </a>
            </li>
          </ul>
          <div class="tab-content" style="width:100%;background:#fff">
            <div
              class="embed-responsive-16by9 tab-pane show active tc1"
              id="tc1"
              v-if="curindex=='tc1'"
            >
              <div class="card-box mb-2" style="position:relative">
                <!-- map -->
                <div class="address_list" v-if="mapList.length>0">
                  <div
                    v-bind:class="[cad=='k'+idNum ? 'active line':'line']"
                    v-for="(obj, idNum) in mapList"
                    @click="gotowindow(idNum)"
                    v-bind:key="idNum"
                  >
                    <b v-if="obj['事業所'] && obj['事業所'] != 'undefined'">{{ obj['事業所']}}</b>
                    <b v-else>{{ '未命名'}}</b>
                    <br />
                    <p>
                      <t
                        v-if="obj['Corp_Name'] && obj['Corp_Name'] != 'undefined'"
                      >{{ tableHeaders[1] + ': ' + obj['Corp_Name']}}</t>
                      <br />
                      {{ tableHeaders[2] + ': ' + obj['address']}}
                    </p>
                  </div>
                </div>
                <div id="googleMap" class="google"></div>
              </div>
            </div>
            <div
              class="embed-responsive-16by9 tab-pane show active tc2"
              id="tc2"
              v-if="curindex=='tc2'"
            >
              <div class="col-12">
                <div class="responsive-table-plugin">
                  <div class="table-rep-plugin">
                    <div class="table-responsive">
                      <table class="table table-striped focus-on">
                        <thead>
                          <tr>
                            <th
                              v-for="tableHeader in tableHeaders"
                              :key="tableHeader"
                              :class="'table-row-' + tableHeader"
                            >{{ tableHeader }}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody v-if="mapList.length>0">
                          <tr
                            v-for="(obj, idNum) in mapList"
                            :key="idNum"
                          
                          >
                            <td>{{(obj['事業所']==undefined ?'未命名':obj['事業所'])}}</td>
                            <td>
                              <a
                                style="text-decoration:underline;"
                                v-if="obj['社名'] && obj['社名'] !='undefined'"
                                :href="'#/CorpReference/0?dataId=0&dataType=100&dataName=' + obj['社名'] + '&code=&dataNumber=&curindex=tc1-2&tolink=search_result'"
                                target="_blank"
                              >{{(obj['Corp_Name']==undefined ?'未命名':obj['Corp_Name'])}}</a>
                            </td>
                            <td>{{(obj['address']==undefined ?'':obj['address'])}}</td>
                            <td>{{(obj['distance']==undefined ?'':obj['distance'])}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="p-2">
                        <code v-if="mapList.length==0">{{$language('表示データがありません')}}</code>
                      </div>
                    </div>
                    <!-- end .table-responsive -->
                  </div>
                  <!-- end .table-rep-plugin-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb" style="padding:15px;" v-if="type=='company'">
      <div class="col-lg-12">
        <div class="search-result-box card-box">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <div class="pb-4">
                <div class="input-group m-t-10">
                  <input
                    type="text"
                    class="form-control"
                    v-model="listStrage.query"
                    @keyup.enter="search"
                    placeholder="企業名称を指定して下さい："
                  />
                  <span class="input-group-append">
                    <button
                      type="button"
                      class="btn waves-effect waves-light btn-black"
                      @click="search"
                    >
                      <i class="fa fa-search mr-1"></i>
                      <div class="lang" v-html="lan.search"></div>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="types">
            <div class="custom-input">
              <input type="radio" value="company" v-model="type" />
              <div class="show-box radio"></div>
            </div>
            <div class="lang" v-html="lan.search_by_firm_name"></div>&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="custom-input">
              <input type="radio" value="address" v-model="type" />
              <div class="show-box radio"></div>
            </div>
            <div class="lang" v-html="lan.search_by_address"></div>
          </div>
          <!-- end row -->

          <ul class="nav nav-tabs nav-bordered">
            <li class="nav-item">
              <a
                href="javascript:void(0)"
                data-toggle="tab"
                aria-expanded="true"
                class="nav-link active"
              >
                <div class="lang" v-html="lan.search_result"></div>
                <span class="badge badge-success ml-1 btn-black ">{{listData.length}}</span>
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane active" id="home">
              <div class="row">
                <div class="col-md-12">
                  <!-- Start Content-->
                  <div>
                    <div
                      class="responsive-table-plugin btn-toolbar align-items-center justify-content-between"
                    >
                      <div class="form-inline toolbar-display">
                        <div class="mr-2 toolbar-display-count">
                          <span class="mr-1">
                            <div class="lang" v-html="lan.limit"></div>
                          </span>
                          <div>
                            <select
                              class="form-control mt-0"
                              v-model.lazy="listStrage.limit"
                              @change="search"
                            >
                              <option>10</option>
                              <option>50</option>
                              <option>100</option>
                              <option>200</option>
                              <option>500</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-center p-5" v-if="isLoading">
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div v-else-if="!jsonErr && !checkKeys.length">
                      <!-- 表示項目無し -->
                      <code>
                        <div class="lang" v-html="lan.no_data"></div>
                      </code>
                    </div>
                    <div class="responsive-table-plugin" v-else-if="!jsonErr">
                      <div class="table-rep-plugin">
                        <div class="table-responsive">
                          <table class="table table-striped focus-on">
                            <thead>
                              <tr>
                                <th
                                  v-for="tableHeader in tableHeaders2"
                                  :key="tableHeader"
                                  :class="'table-row-' + tableHeader"
                                  v-show="checkKeys.includes(tableHeader)"
                                >{{$language(tableHeader)}}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody v-if="jsonObj.count && checkKeys.length">
                              <tr
                                v-for="(obj, idNum) in listData"
                                :key="idNum"
                              >
                                <list-table-row :row="obj" :checkList="checkKeys" info_id="corpname" :jump_url="'/'+viewParams.name+'/'+(obj[viewParams.articleParamId]!=undefined ? obj[viewParams.articleParamId]:0)+'?dataId='+(obj[viewParams.articleParamId]!=undefined ? obj[viewParams.articleParamId]:0)+'&dataType='+viewParams.dataType+'&dataName='+(viewParams.dataType==100 ?obj['corpname']:obj['Corp_Name'])+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+(viewParams.dataType==100 ?obj['corp_num']:obj['Corp_Num'])+'&curindex=tc1-2&tolink=search_result'"/>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end .table-responsive -->
                      </div>
                      <!-- end .table-rep-plugin-->

                      <div class="d-flex justify-content-end mt-1" v-if="jsonObj.total_count">
                        <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
                        &nbsp;~&nbsp;
                        <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj.count }}</span>
                        &nbsp;/&nbsp;
                        <span>{{ jsonObj.total_count }}</span>
                      </div>
                      <div class="d-flex justify-content-end mt-1" v-else>
                        <span>0</span>
                        &nbsp;~&nbsp;
                        <span>0</span>
                        &nbsp;/&nbsp;
                        <span>0</span>
                      </div>

                      <ul
                        class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1"
                        v-if="jsonObj.total_count"
                      >
                        <li class="footable-page-arrow mb-1">
                          <a href="#" @click.prevent.stop="pagenateKey('first')">«</a>
                        </li>
                        <li class="footable-page-arrow mb-1">
                          <a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a>
                        </li>
                        <li
                          class="footable-page mb-1"
                          :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }"
                          v-for="num in maxPage"
                          :key="num"
                        >
                          <a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a>
                        </li>
                        <li class="footable-page-arrow mb-1">
                          <a href="#" @click.prevent.stop="pagenateKey('next')">›</a>
                        </li>
                        <li class="footable-page-arrow mb-1">
                          <a href="#" @click.prevent.stop="pagenateKey('last')">»</a>
                        </li>
                      </ul>
                    </div>
                    <!-- end .responsive-table-plugin-->
                    <div v-else>
                      <code>
                        <div class="lang" v-html="lan.error_data"></div>
                      </code>
                    </div>
                  </div>
                  <!-- container -->

                  <div class="clearfix"></div>
                </div>
              </div>
            </div>

            <!-- end All results tab -->
          </div>
        </div>
      </div>
    </div>
    <div class="mb" style="padding:15px;" v-if="type=='building'">
      <div class="col-lg-12">
        <div class="search-result-box card-box">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <div class="pb-4">
                <div class="input-group m-t-10">
                  <input
                    type="text"
                    class="form-control"
                    v-model="listStrage.query"
                    @keyup.enter="search"
                    placeholder
                  />
                  <span class="input-group-append">
                    <button
                      type="button"
                      class="btn waves-effect waves-light btn-blue"
                      @click="search"
                    >
                      <i class="fa fa-search mr-1"></i>
                      <div class="lang" v-html="lan.search"></div>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="types">
            <input type="radio" value="company" v-model="type" />
            <div class="lang" v-html="lan.search_by_firm_name"></div>&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="radio" value="address" v-model="type" />
            <div class="lang" v-html="lan.search_by_address"></div>
          </div>
          <!-- end row -->

          <ul class="nav nav-tabs nav-bordered">
            <li class="nav-item">
              <a
                href="javascript:void(0)"
                data-toggle="tab"
                aria-expanded="true"
                class="nav-link active"
              >
                <div class="lang" v-html="lan.search_result"></div>
                <span class="badge badge-success ml-1">{{listData.length}}</span>
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane active" id="home">
              <div class="row">
                <div class="col-md-12">
                  <!-- Start Content-->
                  <div>
                    <div
                      class="responsive-table-plugin btn-toolbar align-items-center justify-content-between"
                    >
                      <div class="form-inline toolbar-display">
                        <div class="mr-2 toolbar-display-count">
                          <span class="mr-1">
                            <div class="lang" v-html="lan.limit"></div>
                          </span>
                          <div>
                            <select
                              class="form-control mt-0"
                              v-model.lazy="listStrage.limit"
                              @change="search"
                            >
                              <option>10</option>
                              <option>50</option>
                              <option>100</option>
                              <option>200</option>
                              <option>500</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-center p-5" v-if="isLoading">
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div v-else-if="!jsonErr && !checkKeys.length">
                      <!-- 表示項目無し -->
                      <code>
                        <div class="lang" v-html="lan.no_data"></div>
                      </code>
                    </div>
                    <div class="responsive-table-plugin" v-else-if="!jsonErr">
                      <div class="table-rep-plugin">
                        <div class="table-responsive">
                          <table class="table table-striped focus-on">
                            <thead>
                              <tr>
                                <th
                                  v-for="tableHeader in tableHeaders2"
                                  :key="tableHeader"
                                  :class="'table-row-' + tableHeader"
                                  v-show="checkKeys.includes(tableHeader)"
                                >{{$language(tableHeader)}}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody v-if="jsonObj.count && checkKeys.length">
                              <tr v-for="(obj, idNum) in listData" :key="idNum">
                               
                                <list-table-row :row="obj" :checkList="checkKeys" />
                                <td class="table-focus-on-td">
                                  <transition transition name="slide-btn">
                                    <router-link
                                      v-if="isFocus === idNum"
                                      :to="'/'+viewParams.name+'/'+(obj[viewParams.articleParamId]!=undefined ? obj[viewParams.articleParamId]:0)+'?dataId='+(obj[viewParams.articleParamId]!=undefined ? obj[viewParams.articleParamId]:0)+'&dataType='+viewParams.dataType+'&dataName='+(viewParams.dataType==100 ?obj['corpname']:obj['Corp_Name'])+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+(viewParams.dataType==100 ?obj['corp_num']:obj['Corp_Num'])+'&curindex=tc1&tolink=search_result'"
                                      class="btn btn-default"
                                    >
                                      {{$language('詳細')}}
                                      <i class="mdi mdi-arrow-right-bold"></i>
                                    </router-link>
                                  </transition>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end .table-responsive -->
                      </div>
                      <!-- end .table-rep-plugin-->

                      <div class="d-flex justify-content-end mt-1" v-if="jsonObj.total_count">
                        <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
                        &nbsp;~&nbsp;
                        <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj.count }}</span>
                        &nbsp;/&nbsp;
                        <span>{{ jsonObj.total_count }}</span>
                      </div>
                      <div class="d-flex justify-content-end mt-1" v-else>
                        <span>0</span>
                        &nbsp;~&nbsp;
                        <span>0</span>
                        &nbsp;/&nbsp;
                        <span>0</span>
                      </div>

                      <ul
                        class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1"
                        v-if="jsonObj.total_count"
                      >
                        <li class="footable-page-arrow mb-1">
                          <a href="#" @click.prevent.stop="pagenateKey('first')">«</a>
                        </li>
                        <li class="footable-page-arrow mb-1">
                          <a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a>
                        </li>
                        <li
                          class="footable-page mb-1"
                          :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }"
                          v-for="num in maxPage"
                          :key="num"
                        >
                          <a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a>
                        </li>
                        <li class="footable-page-arrow mb-1">
                          <a href="#" @click.prevent.stop="pagenateKey('next')">›</a>
                        </li>
                        <li class="footable-page-arrow mb-1">
                          <a href="#" @click.prevent.stop="pagenateKey('last')">»</a>
                        </li>
                      </ul>
                    </div>
                    <!-- end .responsive-table-plugin-->
                    <div v-else>
                      <code>
                        <div class="lang" v-html="lan.error_data"></div>
                      </code>
                    </div>
                  </div>
                  <!-- container -->

                  <div class="clearfix"></div>
                </div>
              </div>
            </div>

            <!-- end All results tab -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTableRow from "@/components/ListTableRow.vue";
import Headers from "@/components/Headers.vue";
import router from "@/router";
import axios from "axios";
import { inject } from "vue";
import Ctooltip from "@/components/Ctooltip";
//import { MarkerClusterer } from "@googlemaps/markerclusterer";
export default {
  name: "search_result",
  components: {
    ListTableRow,
    Headers,
    Ctooltip,
  },
  data() {
    const { currentRoute } = router;
    return {
      center: {
        lat: Number,
        lng: Number,
      },
      address:
        currentRoute.value.query.keyword == undefined
          ? ""
          : currentRoute.value.query.keyword,
      distance: 500,
      type:
        currentRoute.value.query.type == undefined
          ? "company"
          : currentRoute.value.query.type,
      mapList: [],
      reload: 0,
      cad: "",
      map: null,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          MapTypeId: {},
          Marker: {},
          InfoWindow: {},
          Circle: {},
        },
      },
      markers: [],
      infowindow: [],
      curindex: "tc1",
      tableHeaders: ["Office", "Corp Name", "Address", "Distance"],
      // company
      viewParams: {
        name: "CorpReference",
        apiUrl: "/v1/corps_reference/",
        articleView: "CorpReferenceArticle",
        articleParamId: "id",
        dataType: 100,
      },
      offset: 0,
      listStrage: {
        query: "",
        limit: 100,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object],
      },
      tableHeaders2: [],
      listData: [],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: false,
      downloadAlert: "",

      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
      cur_lan: "jp",
      ccenter: "",
      radius: 0,
      isPopup: false,
    };
  },
  $route() {
    this.cur_lan = localStorage.getItem("cur_lan") != "en" ? "jp" : "en";

    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
  },
  mounted() {
    const { currentRoute } = router;
    this.cur_lan = localStorage.getItem("cur_lan") != "en" ? "jp" : "en";
    this.tableHeaders = [
      this.lan.offices,
      this.lan.corp_name,
      this.lan.address,
      this.lan.distance,
    ];

    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
    if (this.type == "company") {
      this.listStrage.query =
        currentRoute.value.query.keyword == undefined
          ? ""
          : currentRoute.value.query.keyword;

      if (localStorage.getItem(this.viewParams.name + "_limit")) {
        this.listStrage.limit = localStorage.getItem(
          this.viewParams.name + "_limit"
        );
      }
      if (
        sessionStorage.getItem(this.viewParams.name + "_query") &&
        this.listStrage.query == ""
      ) {
        this.listStrage.query = sessionStorage.getItem(
          this.viewParams.name + "_query"
        );
      } else {
        sessionStorage.setItem(
          this.viewParams.name + "_query",
          this.listStrage.query
        );
      }
      if (sessionStorage.getItem(this.viewParams.name + "_paged")) {
        this.listStrage.paged = Number(
          sessionStorage.getItem(this.viewParams.name + "_paged")
        );
      }
    } else {
      this.address =
        currentRoute.value.query.keyword == undefined
          ? ""
          : currentRoute.value.query.keyword;
      this.loadGoogleMapsScript().then(() => {
        this.google = window.google;
        this.autoaddress();
        this.initializeMap();
      });
    }

    this.search();
  },
  methods: {
    initdraw(map) {
      var _that = this;
      var drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.CIRCLE,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
        },

        circleOptions: {
          strokeColor: "#003f2d",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#003f2d",
          fillOpacity: 0.35,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setMap(map);
      window.google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        function (event) {
          if (event.type == "circle") {
            _that.ccenter = JSON.parse(
              JSON.stringify(event.overlay.getCenter())
            );
            _that.radius = parseInt(event.overlay.getRadius());
            new window.google.maps.Geocoder().geocode(
              {
                location: event.overlay.getCenter(),
              },
              function (results, status) {
                console.log(results);
                console.log(_that.ccenter);
                if (status === "OK" && results[0]) {
                  _that.address = results[0].formatted_address;
                  _that.search();
                  window.google.maps.event.addListener(
                    event.overlay,
                    "radius_changed",
                    function () {
                      _that.radius = parseInt(event.overlay.getRadius());
                      _that.search();
                    }
                  );
                }
              }
            );
          }
        }
      );
    },
    autoaddress() {
      let address1Field;
      address1Field = document.querySelector("#address");
      new this.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: {
          country: ["jp"],
        },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });
      address1Field.focus();
    },
    toggleDDMenu: function () {
      this.ddMenu = !this.ddMenu;
    },
    allCheck: function () {
      this.checkKeys = this.tableHeaders2;
    },
    allUncheck: function () {
      this.checkKeys = [];
    },
    toggleFocus: function (idNum) {
      this.isFocus = idNum;
    },
    search: function () {
      if (this.radius > 0) {
        this.distance = this.radius;
      }

      console.log(this.ccenter + "gg");
      if (this.type == "address") {
        this.address = document.querySelector("#address").value;
        axios
          .get(
            process.env.VUE_APP_API2_URL_BASE +
              "/getLocationByAddresRecommendation?address=" +
              this.address +
              "&center=" +
              (this.ccenter == ""
                ? ""
                : this.ccenter.lat + "," + this.ccenter.lng) +
              "&distance=" +
              this.distance,
            {
              params: {
                id_token: localStorage.getItem("id_token"),
              },
            }
          )
          .then((response) => {
            this.mapList = response.data.data;
            this.initializeMap();
          })
          .catch(
            (error) => (
              console.log(error),
              (this.jsonErr = true),
              (this.isLoading = false)
            )
          );
      } else {
        this.isLoading = true;
        localStorage.setItem(
          this.viewParams.name + "_limit",
          this.listStrage.limit
        );
        sessionStorage.setItem(
          this.viewParams.name + "_query",
          this.listStrage.query
        );
        sessionStorage.setItem(this.viewParams.name + "_paged", 1);
        console.log(this.listStrage.query + "fff");
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: 0, //開始位置
              limit: this.listStrage.limit, //表示件数
              q: this.listStrage.query, //キーワード
              id_token: localStorage.getItem("id_token"),
            },
          })
          .then(
            (response) => (
              (this.jsonObj = response.data),
              (this.tableHeaders2 = this.jsonObj.headers),
              (this.listData = this.jsonObj.data),
              (this.checkKeys =
                this.listData[0] != undefined
                  ? Object.keys(this.listData[0])
                  : []),
              (this.maxPage = Math.ceil(
                this.jsonObj.total_count / this.listStrage.limit
              )),
              (this.listStrage.paged = 1),
              (this.isLoading = false),
              (this.jsonErr = false)
            )
          )
          .catch((error) => {
            console.log(error), (this.jsonErr = true), (this.isLoading = false);
          });
      }
    },
    pagenateNum: function (num) {
      this.isLoading = true;
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (num - 1) * this.listStrage.limit, //開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query, //キーワード
          },
        })
        .then(
          (response) => (
            (this.jsonObj = response.data),
            (this.tableHeaders2 = this.jsonObj.headers),
            (this.listData = this.jsonObj.data),
            (this.maxPage = Math.ceil(
              this.jsonObj.total_count / this.listStrage.limit
            )),
            (this.listStrage.paged =
              Math.round(this.jsonObj.offset / this.listStrage.limit) + 1),
            sessionStorage.setItem(
              this.viewParams.name + "_paged",
              this.listStrage.paged
            ),
            (this.isLoading = false)
          )
        )
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    },
    pagenateKey: function (key) {
      switch (key) {
        case "next":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * this.listStrage.paged;
          }
          break;
        case "prev":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2);
          }
          break;
        case "first":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = 0;
          }
          break;
        case "last":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.maxPage - 1);
          }
          break;
        default:
          this.isLoading = false;
          console.log("Non-existent key");
      }
      if (this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              q: this.listStrage.query, //キーワード
            },
          })
          .then(
            (response) => (
              (this.jsonObj = response.data),
              (this.tableHeaders2 = this.jsonObj.headers),
              (this.listData = this.jsonObj.data),
              (this.maxPage = Math.ceil(
                this.jsonObj.total_count / this.listStrage.limit
              )),
              (this.listStrage.paged =
                Math.round(this.jsonObj.offset / this.listStrage.limit) + 1),
              sessionStorage.setItem(
                this.viewParams.name + "_paged",
                this.listStrage.paged
              ),
              (this.isLoading = false)
            )
          )
          .catch((error) => {
            console.log(error), (this.jsonErr = true), (this.isLoading = false);
          });
      }
    },
    pageHide: function (num) {
      if (num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4) {
        return true;
      }
    },
    listDownload: function () {
      let xmldata;
      axios
        .post(
          this.viewParams.apiUrl + "download",
          {
            data: this.listData,
            items: this.checkKeys,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          xmldata = response.data;
          this.$nextTick(() => {
            let blob = new Blob([xmldata]);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "corps.xlsx";
            link.click();
          });
          this.downloadAlert = "";
          this.isLoading = false;
        })
        .catch((error) => {
          this.downloadAlert = this.$language("ダウンロードに失敗しました。");
          console.log(error);
          this.isLoading = false;
        });
    },

    tab: function (tid) {
      this.curindex = tid;
      if (tid == "tc1") {
        var that = this;
        setTimeout(function () {
          that.initializeMap();
        }, 800);
      }
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=places,drawing,geometry&v=weekly&channel=2";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
    initializeMap() {
      const mapContainer = this.$el.querySelector("#googleMap");

      const { Map, MapTypeId, Marker, InfoWindow, Circle } = this.google.maps;
      const Geocoder = new this.google.maps.Geocoder();
      var _that = this;
      this.cad = "";
      // map create
      var zoom = 7;
      if (_that.distance > 3000000) {
        zoom = 3;
      } else if (_that.distance > 2000000) {
        zoom = 4;
      } else if (_that.distance > 1000000) {
        zoom = 5;
      } else if (_that.distance > 500000) {
        zoom = 6;
      } else if (_that.distance > 200000) {
        zoom = 7;
      } else if (_that.distance > 100000) {
        zoom = 8;
      } else if (_that.distance > 50000) {
        zoom = 9;
      } else if (_that.distance > 20000) {
        zoom = 10;
      } else if (_that.distance > 10000) {
        zoom = 11;
      } else if (_that.distance > 5000) {
        zoom = 12;
      } else if (_that.distance > 2000) {
        zoom = 13;
      } else if (_that.distance > 1000) {
        zoom = 14;
      } else if (_that.distance > 500) {
        zoom = 15;
      } else if (_that.distance > 200) {
        zoom = 16;
      } else if (_that.distance > 100) {
        zoom = 17;
      } else if (_that.distance > 50) {
        zoom = 18;
      } else if (_that.distance > 20) {
        zoom = 19;
      } else {
        zoom = 20;
      }
      _that.map = new Map(mapContainer, {
        zoom: zoom,
        mapTypeId: MapTypeId.ROADMAP,
      });

      new Geocoder.geocode(
        {
          address: _that.address,
        },
        function (results, status) {
          if (status === "OK" && results[0]) {
            let locate = results[0].geometry.location;
            let center = {
              lat: Number(locate.lat()),
              lng: Number(locate.lng()),
            };

            // set locate
            _that.map.setCenter(center);
            var cc = new Circle({
              strokeColor: "#003f2d",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#003f2d",
              fillOpacity: 0.35,
              map: _that.map,
              center: center,
              editable: true,
              radius: parseInt(_that.distance),
            });
            _that.google.maps.event.addListener(
              cc,
              "radius_changed",
              function () {
                _that.ccenter = JSON.parse(JSON.stringify(cc.getCenter()));
                _that.radius = parseInt(cc.getRadius());
                _that.search();
              }
            );
            _that.initdraw(_that.map);
            //_that.mapList.push({latitude:locate.lat(),longitude:locate.lng(),address:_that.address})
            _that.markers = [];
            _that.infowindow = [];
            if (_that.mapList.length > 0) {
              _that.mapList.map((obj, i) => {
                _that.infowindow[i] = new InfoWindow({
                  content:
                    '<div id="content">' +
                    '<div id="siteNotice">' +
                    "</div>" +
                    '<h3 id="firstHeading" class="firstHeading">' +
                    (obj["事業所"] && obj["事業所"] != "undefined"
                      ? obj["事業所"]
                      : "未命名") +
                    "</h3>" +
                    '<div id="bodyContent">' +
                    "<p>" +
                    _that.tableHeaders[1] +
                    ": " +
                    obj["Corp_Name"] +
                    "<br>" +
                    _that.tableHeaders[2] +
                    ": " +
                    obj["address"] +
                    "</p>" +
                    "</div>" +
                    "</div>",
                });
                _that.markers[i] = new Marker({
                  position: {
                    lat: parseFloat(obj.MapCenterLat),
                    lng: parseFloat(obj.MapCenterLong),
                  },
                  map: _that.map,
                  title: obj.BuildingName == undefined ? "" : obj.BuildingName,
                });
                _that.markers[i].addListener("click", () => {
                  _that.infowindow[i].open({
                    anchor: _that.markers[i],
                    map: _that.map,
                    shouldFocus: false,
                  });
                });
              });
            }
            //pano.setPosition(_that.center);
          } else {
            console.log("failed load google api  : " + status);
            return;
          }
        }
      );
    },

    gotowindow(i) {
      var _that = this;
      if (this.cad != "") {
        _that.infowindow[this.cad.replace("k", "")].close();
      }
      this.cad = "k" + i;
      _that.infowindow[i].open({
        anchor: _that.markers[i],
        map: _that.map,
        shouldFocus: false,
      });
    },
  },
  watch: {
    $route() {},
  },
};
</script>

<style lang="scss" scoped>

.btn-black{
    color: #fff;
    background-color: #003f2d;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.google {
  height: 800px;
}

.overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  opacity: 0.8;
  background: #4477aa;
  border: solid 3px #336699;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #333;
  text-shadow: 1px 1px 1px #666;
  padding: 0 4px;
}

.overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #336699;
}

.overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #336699;
}

.row {
  font-size: 14px;
}

pre {
  font-family: "Ubuntu Mono";
  font-size: 14px;
}

.row {
  zoom: 1;
  margin-left: -20px;
}

.row:before,
.row:after {
  display: table;
  content: "";
  zoom: 1;
}

.row:after {
  clear: both;
}

.row > [class*="span"] {
  display: inline;
  float: left;
  margin-left: 20px;
}

.span1 {
  width: 40px;
}

.span2 {
  width: 100px;
}

.span3 {
  width: 160px;
}

.span4 {
  width: 220px;
}

.span5 {
  width: 280px;
}

.span6 {
  width: 340px;
}

.span7 {
  width: 400px;
}

.span8 {
  width: 460px;
}

.span9 {
  width: 520px;
}

.span10 {
  width: 580px;
}

.span11 {
  width: 640px;
}

.span12 {
  width: 700px;
}

.span13 {
  width: 760px;
}

.span14 {
  width: 820px;
}

.span15 {
  width: 880px;
}

.span16 {
  width: 940px;
}

.span17 {
  width: 1000px;
}

.span18 {
  width: 1060px;
}

.span19 {
  width: 1120px;
}

.span20 {
  width: 1180px;
}

.span21 {
  width: 1240px;
}

.span22 {
  width: 1300px;
}

.span23 {
  width: 1360px;
}

.span24 {
  width: 1420px;
}

pre.prettyprint {
  background: #fff;
  border-color: #c1c1c1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px #999;
  -moz-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
  padding: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.label {
  font-size: 10.998px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999999;
  margin: 2px 6px;
}

.label {
  padding: 1px 4px 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.notice {
  background-color: #3a87ad;
}

form input[type="text"] {
  border: solid 1px #999999;
  padding: 2px;
}

.edit_marker {
  width: 250px;
  height: 80px;
}

.edit_marker p {
  margin: 0;
}

.address_list {
  position: absolute;
  top: 80px;
  left: 30px;
  z-index: 999;
  background: #fff;
  max-height: 500px;
  max-width: 320px;
  overflow-y: auto;
}

.address_list .line {
  border-top: 1px solid #eee;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
}

.address_list .line:nth-child(0) {
  border-top: 0;
}

.address_list .active {
  background: #003f2d;
  color: #fff;
}

.nav-tabs li {
  cursor: pointer;
}

.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {
  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: 0.5em;
    width: 100%;
  }
}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: 0.15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -0.5rem;
  top: -0.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .dropdown-menu.show li {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 500px) {
  .dropdown-menu.show li {
    flex-basis: 100%;
  }
}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover,
table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -0.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: 0.35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: 0.15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}

.table td .table-data-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.types {
  display: table;
  margin: 0 auto;
}

.help {
  position: relative;
  margin-left: 10px;
  display: inline-block;
}

.help-box {
  margin-top: 10%;
  width: 60%;
}

.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: 0.15rem;
  box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.tagbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closes {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 4px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  padding: 2px 9px;
  border-radius: 15px;
}
</style>
